@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

* {
	box-sizing: border-box;
}

html {
	height: 100vh;
	height: -webkit-fill-available;
}

body {
	height: 100%;
	font-family: "Poppins", sans-serif;
	margin: 0;
}

#root {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.container {
	width: 100%;
	max-width: 800px;
	border: 1px solid #000000;
}

.header {
	display: flex;
	justify-content: center;
	padding: 30px;
}

.header img {
	max-width: 250px;
	max-height: 150px;
}

.content {
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}

.content .text {
	text-align: center;
}

.content .buttons {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
	width: 100%;
	max-width: 500px;
}

.form {
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

textarea {
	border: 1px solid #ccc;
	border-radius: 5px;
	display: block;
	width: 100%;
	height: 200px;
}

button {
	border: 0;
	border-radius: 5px;
	display: block;
	width: 100%;
	background-color: #2e2e2e;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 40px;
	font-size: 16px;
	flex-direction: column;
	gap: 5px;
	padding: 5px;
}

button .icon {
	font-size: 30px;
}

button.secondary {
	background-color: #8d8d8d;
	color: #fff;
}

.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
